import axios from "axios";
import { API_URL } from "../../config/env";
import { getToken } from "../utils/token";
import { GetResponse } from "../../utils/generalServiceResponses";

export type Tag = {
  id: number
  name: string;
  description: string | null;
  totalProducts: number;
};


export async function getAllTag(): Promise<GetResponse<{ tags: Tag[] }>> {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/tag", {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export type TagDTO = {
  name: string;
  description: string | null;
};

export async function postNewTag(DTO: TagDTO) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(API_URL + "/tag", DTO, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

export async function deleteTag(id: number) {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.delete(API_URL + "/tag/" + id, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

export async function getTagById(id: number): Promise<GetResponse<Tag>> {
  const token = await getToken();

  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(API_URL + "/tag/" + id, {
      headers,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export type UpdateTagDTO = {
  id: number;
  name: string;
  description: string | null;
};

export async function updateSupplier(DTO: UpdateTagDTO) {
  const token = await getToken();
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.patch(API_URL + "/tag", DTO, { headers });
    return response.data;
  } catch (e) {
    console.warn(e);
  }
}

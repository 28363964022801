import { Button, Grid } from "@mui/material";
import "./CartPayDrawer.scss";
import { useState } from "react";
import moment from "moment";
import icons from "../../../utils/icons";
import { grayColor, yellowColor3 } from "../../../utils/VARIABLES";
import { primaryColor1 } from "../../../utils/VARIABLES";
import { numericFormatter } from "../../../utils/formatters";
import { AlertType } from "../../kit/Alert";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import Typography from "../../kit/Typography";
import NumberInput from "../../kit/Inputs/Number";
import Checkbox from "../../kit/Selectors/Checkbox";
import { Cart, collectCart, CollectCartDTO } from "../../../services/cart";

interface Props {
  cart: Cart;
  setOpenModal: React.Dispatch<React.SetStateAction<Cart | null>>;
  updatePage: () => void;
  setAlertStatus: (value: AlertType) => void;
  label?: string;
}

const defaultCollectCartDTO = (cart: Cart): CollectCartDTO => {
  return {
    paymentMethod: "Cash",
    changeReturned: true,
    amountPaid: null,
    collectedDate: moment().format("YYYY-MM-DD HH:mm")
  }
};

export default function CartPayDrawer(props: Props) {
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");
  const [collectCartDTO, setCollectCartDTO] =
    useState<CollectCartDTO>(defaultCollectCartDTO(props.cart));

  const minAmountToPay = props.cart.totalAmount - 49;
  const disabled =
    drawerStatus === "loading" ||
    (!!collectCartDTO.amountPaid && collectCartDTO.amountPaid < minAmountToPay);

  const handleCollectCart = () => {
    setDrawerStatus("loading");
    (async () => {
      if(collectCartDTO.amountPaid === null) collectCartDTO.amountPaid = props.cart.totalAmount;
      const response = await collectCart(collectCartDTO);
      if (response.status === 201) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  return (
    <Grid container className="CollectCartDrawer">
      <SideDrawer
        disabled={disabled}
        drawerStatus={drawerStatus}
        title="Cobrar"
        saveOnclick={handleCollectCart}
        saveLabel="Cobrar"
        closeDrawer={() => props.setOpenModal(null)}
        open={props.cart !== null}
      >
        <Grid container>
          <Grid className="box-payment-methods" container>
            <Grid item xs={4}>
              <Button
                style={{
                  borderColor:
                    collectCartDTO?.paymentMethod === "Cash"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() =>
                  setCollectCartDTO((prevState: CollectCartDTO) => ({
                    ...prevState,
                    paymentMethod: "Cash",
                    amountPaid: null,
                  }))
                }
                className="payment-method"
              >
                <Grid
                  className="payment-method-button"
                  onClick={() =>
                    setCollectCartDTO((prevState: CollectCartDTO) => ({
                      ...prevState,
                      paymentMethod: "Transfer",
                      amountPaid: null,
                    }))
                  }
                  container
                >
                  <Grid
                    className={`payment-icon ${collectCartDTO?.paymentMethod}`}
                    container
                  >
                    {icons.simpleCash({
                      width: 28,
                      height: 28,
                      fill:
                        collectCartDTO?.paymentMethod === "Cash"
                          ? primaryColor1
                          : undefined,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        collectCartDTO?.paymentMethod === "Cash"
                          ? primaryColor1
                          : undefined
                      }
                      variant="paragraph-10"
                    >
                      Efectivo
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-transfer" item xs={4}>
              <Button
                style={{
                  borderColor:
                    collectCartDTO?.paymentMethod === "Transfer"
                      ? primaryColor1
                      : undefined,
                }}
                onClick={() =>
                  setCollectCartDTO((prevState: CollectCartDTO) => ({
                    ...prevState,
                    paymentMethod: "Transfer",
                    amountPaid: null,
                  }))
                }
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    {icons.bank({
                      fill:
                        collectCartDTO?.paymentMethod === "Transfer"
                          ? primaryColor1
                          : grayColor,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        collectCartDTO?.paymentMethod === "Transfer"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Transfer.
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid className="payment-methods-card" item xs={4}>
              <Button
                style={{
                  borderColor:
                    collectCartDTO?.paymentMethod === "Card"
                      ? primaryColor1
                      : grayColor,
                }}
                onClick={() =>
                  setCollectCartDTO((prevState: CollectCartDTO) => ({
                    ...prevState,
                    paymentMethod: "Card",
                  }))
                }
                className="payment-method"
              >
                <Grid className="payment-method-button" container>
                  <Grid className="payment-icon" container>
                    {icons.card({
                      width: 24,
                      height: 24,
                      color:
                        collectCartDTO?.paymentMethod === "Card"
                          ? primaryColor1
                          : grayColor,
                    })}
                  </Grid>
                  <Grid className="payment-title" container>
                    <Typography
                      color={
                        collectCartDTO?.paymentMethod === "Card"
                          ? primaryColor1
                          : grayColor
                      }
                      variant="paragraph-10"
                    >
                      Débito
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
          {collectCartDTO?.paymentMethod === "Cash" && (
            <Grid className="collect-rent-drawer-cash" container>
              <Grid className="total-to-collect" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Total a cobrar
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={props.cart.totalAmount}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
              <Grid className="amount-accredited-input" container>
                <NumberInput
                  value={collectCartDTO.amountPaid || ""}
                  variant="line"
                  onChange={(amountPaid: number) =>
                    setCollectCartDTO((prevState: CollectCartDTO) => ({
                      ...prevState,
                      amountPaid,
                    }))
                  }
                  label="Monto abonado"
                />
              </Grid>
              {(!!collectCartDTO.amountPaid && collectCartDTO.amountPaid < minAmountToPay) && (
                <Grid className="not-valid-amount-to-paid" container>
                  <Grid className="atention-box" container>
                    <Grid item xs={1} className="attention-box-icon" container>
                      {icons.circularWarning()}
                    </Grid>
                    <Grid item xs={11} className="attention-box-text" container>
                      <Typography
                        color={yellowColor3}
                        className="atention-box-title"
                        variant="paragraph-18"
                        type="bold"
                      >
                        Atención!
                      </Typography>
                      <Typography color={yellowColor3} variant="paragraph-14">
                        <>
                          El monto abonado no puede ser inferior a {numericFormatter(minAmountToPay)}
                        </>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {(
                !!collectCartDTO.amountPaid && collectCartDTO.amountPaid >= minAmountToPay &&
                collectCartDTO.amountPaid < props.cart.totalAmount
              ) && (
                  <Grid className="remaining-balance" container>
                    <Grid container>
                      <Typography variant="paragraph-14">
                        Deuda perdonada
                      </Typography>
                    </Grid>
                    <NumberInput
                      disabled
                      value={Math.abs(props.cart.totalAmount - (collectCartDTO.amountPaid))}
                      variant="line"
                      label=""
                      onChange={() => { }}
                    />
                  </Grid>
                )}
              {!(!collectCartDTO.amountPaid || collectCartDTO.amountPaid <= props.cart.totalAmount) && (
                <>
                  <Grid className="checkbox-mora" container alignItems="center">
                    <Grid item xs={1}>
                      <Checkbox
                        widthIcons={18}
                        checked={!!collectCartDTO.changeReturned}
                        onClick={() =>
                          setCollectCartDTO((prevState: CollectCartDTO) => ({
                            ...prevState,
                            changeReturned: !prevState.changeReturned,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography variant="paragraph-16">
                        Proporcionar el vuelto
                      </Typography>
                    </Grid>
                  </Grid>
                  {!!collectCartDTO.changeReturned && (
                    <Grid className="returned-amount" container>
                      <Grid container>
                        <Typography variant="paragraph-14">
                          Vuelto
                        </Typography>
                      </Grid>
                      <NumberInput
                        disabled
                        value={Math.abs(props.cart.totalAmount - (collectCartDTO.amountPaid))}
                        variant="line"
                        label=""
                        onChange={() => { }}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
          {(
            collectCartDTO?.paymentMethod === "Transfer" ||
            collectCartDTO?.paymentMethod === "Card"
          ) && (
              <Grid className="amount-accredited" container>
                <Grid container>
                  <Typography variant="paragraph-14">
                    Monto abonado
                  </Typography>
                </Grid>
                <NumberInput
                  disabled
                  value={Math.abs(props.cart.totalAmount)}
                  variant="line"
                  label=""
                  onChange={() => { }}
                />
              </Grid>
            )}
        </Grid>
      </SideDrawer>
    </Grid>
  );
}

import { Grid } from "@mui/material";
import { postNewTag, TagDTO } from "../../../services/tags";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import TextInput from "../../kit/Inputs/Text";
import MultilineInput from "../../kit/Inputs/Multiline";

import './CreateTagDrawer.scss';


interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
}

const defaultTagDto = {
  name: "",
  description: ""
}

export function CreateTagDrawer(props: Props) {
  const [tagDTO, setTagDTO] =
    useState<TagDTO>(defaultTagDto);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handlePostTag = () => {
    (async () => {
      let response = await postNewTag(tagDTO);
      if (response) {
        props.updatePage();
        setDrawerStatus("success");
        setTagDTO(defaultTagDto);
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const disable = () => {
    if (!tagDTO.name || tagDTO.name === "") return true;
    return false;
  };

  return (
    <SideDrawer
      title="Crear nuevo etiqueta"
      disabled={disable()}
      saveOnclick={handlePostTag}
      saveLabel="Guardar"
      closeDrawer={() => {
        setDrawerStatus("idle");
        props.setOpenDrawer(false);
      }}
      open={props.openDrawer}
      drawerStatus={drawerStatus}
    >
      <Grid className="create-tag-drawer" container>
        <Grid className="create-tag-drawer-item" container>
          <TextInput
            onChange={(name) =>
              setTagDTO((prevState) => ({
                ...prevState,
                name,
              }))
            }
            label={<span className="required-label">Nombre </span>}
          />
        </Grid>
        <Grid className="create-tag-drawer-item" container>
          <MultilineInput
            label="Descripción"
            value={tagDTO.description || ""}
            rows={5}
            onChange={(description) =>
              setTagDTO((prevState) => ({
                ...prevState,
                description,
              }))
            }
          />
        </Grid>
      </Grid>
    </SideDrawer>
  )
}
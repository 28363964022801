import { Grid } from "@mui/material";
import TextInput from "../../kit/Inputs/Text";
import "./DrawerSupplier.scss";
import { useState } from "react";
import { DrawerStatus, SideDrawer } from "../../kit/SideDrawer";
import { postNewSupplier, SupplierDTO } from "../../../services/supplier";

interface Props {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openDrawer: boolean;
  updatePage: () => void;
}

const defaultSupplierDTO = {
  name: "",
  phone: "",
  email: "",
};

export default function CreateNewSupplierDrawer(props: Props) {
  const [supplierDTO, setSupplierDTO] =
    useState<SupplierDTO>(defaultSupplierDTO);
  const [drawerStatus, setDrawerStatus] = useState<DrawerStatus>("idle");

  const handlePostSupplier = () => {
    (async () => {
      let response = await postNewSupplier(supplierDTO);
      if (response) {
        props.updatePage();
        setDrawerStatus("success");
      } else {
        setDrawerStatus("error");
      }
    })();
  };

  const onCloseModal = () => {
    props.setOpenDrawer(false);
    setSupplierDTO(defaultSupplierDTO);
  };

  const disableSupplierDTO = () => {
    if (supplierDTO.name === "") return true;
    return false;
  };

  return (
    <SideDrawer
      title="Crear nuevo proveedor"
      disabled={disableSupplierDTO()}
      saveOnclick={handlePostSupplier}
      saveLabel="Guardar"
      closeDrawer={() => {
        setDrawerStatus("idle");
        onCloseModal();
      }}
      open={props.openDrawer}
      drawerStatus={drawerStatus}
    >
      <Grid className="create-supplier-drawer" container>
        <Grid className="create-supplier-drawer-item" container>
          <TextInput
            onChange={(name) =>
              setSupplierDTO((prevState) => ({
                ...prevState,
                name,
              }))
            }
            label={<span className="required-label">Nombre </span>}
          />
        </Grid>
        <Grid className="create-supplier-drawer-item" container>
          <TextInput
            onChange={(phone) =>
              setSupplierDTO((prevState) => ({
                ...prevState,
                phone,
              }))
            }
            label={<span className="required-label">Numero</span>}
          />
        </Grid>
        <Grid className="create-supplier-drawer-item" container>
          <TextInput
            onChange={(email) =>
              setSupplierDTO((prevState) => ({
                ...prevState,
                email,
              }))
            }
            label={<span>Email </span>}
          />
        </Grid>
      </Grid>
    </SideDrawer>
  );
}

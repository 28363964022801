import { Grid } from "@mui/material";
import ButtonLevel from "../../components/kit/Buttons";
import "./Cart.scss";
import BasicTable, { BasicTableStatus } from "../../components/kit/BasicTable";
import { useEffect, useRef, useState } from "react";
import { addProductToCart, changeProductQuantity, clearCart, getActiveCart, removeProductFromCart, type Cart } from "../../services/cart";
import { getProductByCodeOrName } from "../../services/product";
import { numericFormatter } from "../../utils/formatters";
import NumberInput from "../../components/kit/Inputs/Number";
import Typography from "../../components/kit/Typography";
import { grayColor, greenColor2 } from "../../utils/VARIABLES";
import { AlertType } from "../../components/kit/Alert";
import CartPayDrawer from "../../components/Cart/CartPayDrawer";


export default function CartDesktop() {
  const [tableStatus, setTableStatus] = useState<BasicTableStatus>("idle");
  const [cart, setCart] = useState<Cart | null>(null);
  const [search, setSearch] = useState<{
    value: string;
    loading: boolean;
    options: {
      id: number;
      label: string;
    }[];
  }>({
    value: "",
    options: [],
    loading: false,
  });
  const [productQuantity, setProductQuantity] = useState<
    { id: number, quantity: number } | null
  >(null);
  const [alertStatusCollect, setAlertStatusCollect] =
    useState<AlertType>("close");
  const [collectCartDrawer, setCollectCartDrawer] = useState<Cart | null>(null);

  const handleGerActiveCart = () => {
    setTableStatus("loading");
    (async () => {
      const response = await getActiveCart();
      if (response.status === 200) {
        setCart(response.data);
        setTableStatus("success");
      } else {
        setTableStatus("error");
      }
    })();
  };
  useEffect(() => {
    handleGerActiveCart();
  }, []);

  const handleClearCart = () => {
    (async () => {
      const response = await clearCart();
      if (response.status === 200) {
        handleGerActiveCart();
        setSearch({
          ...search,
          value: ""
        });
      }
    })();
  }

  const handleSearchProductOptions = () => {
    if (search.value === "") {
      setSearch({
        ...search,
        options: [],
        loading: false
      });
      return;
    }
    setSearch({
      ...search,
      loading: true
    });
    (async () => {
      const response = await getProductByCodeOrName(search.value);
      if (response.status === 200) {
        setSearch({
          ...search,
          options: response.data?.products?.map(product => ({ label: product.name, id: product.id })) || [],
          loading: false
        });
      }
    })();
  }

  const handleAddProductToCart = (productId: number, quantity: number) => {
    (async () => {
      const response = await addProductToCart(productId, quantity);
      if (response.status === 201) {
        handleGerActiveCart();
      }
    })();
  }

  const handleChangeProductQuantity = () => {
    if (!productQuantity || !productQuantity.quantity) return;

    const updatedProduct = productQuantity.id;

    (async () => {
      const response = await changeProductQuantity(productQuantity.id, productQuantity.quantity);
      if (response.status === 201) {
        if (updatedProduct === productQuantity.id)
          setProductQuantity(null);
        handleGerActiveCart();
      }
    })();
  }

  const handleRemoveProductFromCart = (productId: number) => {
    if (!cart) return;
    const findProductQuantity = cart.cartProducts.find(({ product }) => product.id === productId)?.quantity || 0;

    (async () => {
      const response = await removeProductFromCart(productId, findProductQuantity);
      if (response.status === 201) {
        handleGerActiveCart();
      }
    })();
  }

  const timeoutIdRef = useRef<NodeJS.Timeout | number | null>(null);
  useEffect(() => {

    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleChangeProductQuantity, 1000);
  }, [productQuantity]);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleSearchProductOptions, 500);
  }, [search.value]);

  const disablePayButton = !cart ||
    cart.cartProducts.length === 0 ||
    (productQuantity !== null && !(productQuantity.quantity < (cart.cartProducts.find(({ id }) => id === productQuantity.id)?.product.stock || 0)))

  return (
    <Grid container className="cart-page">
      <Grid container className="cart-content">
        <Grid container className="cart-content-header" alignItems="center">
          <Grid item xs={3} className="cart-content-header-total-to-collect">
            <Typography className="cart-content-header-total-to-collect-title" variant="paragraph-16" type="bold">
              Total a cobrar
            </Typography>
            <Typography className="cart-content-header-total-to-collect-amount" variant="paragraph-24" color={greenColor2} type="semibold">
              {numericFormatter(cart?.totalAmount || 0)}
            </Typography>
          </Grid>
          <Grid item xs className="cart-content-header-buttons">
            <Grid item className="cart-content-button">
              <ButtonLevel
                title="Limpiar carrito"
                size="medium"
                onClick={handleClearCart}
                variant="outlined"
                disabled={!cart || cart.cartProducts.length === 0}
              />
            </Grid>
            <Grid item className="cart-content-button">
              <ButtonLevel
                title="Cobrar"
                size="medium"
                onClick={() => setCollectCartDrawer(cart)}
                variant="contained"
                disabled={disablePayButton}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container className="cart-content-products">
          <BasicTable
            noFoundMessage={{
              title: "Busca para agregar productos",
              description: "Usa el buscador y selecciona el producto que deseas agregar al carrito."
            }}
            columns={["Nombre", "Precio unitario/kg", "Unidades / Peso", "Precio total", ""]}
            rows={cart?.cartProducts.map(({ quantity, product }) => [
              product.name,
              numericFormatter(product.amount),
              (
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <NumberInput
                      key={product.id}
                      placeholder={product.type === "weight" ? quantity + " Gr." : quantity + " Un."}
                      variant="standard"
                      value={productQuantity && productQuantity.id === product.id ? productQuantity.quantity : quantity}
                      onChange={(value) => {
                        setProductQuantity({
                          id: product.id,
                          quantity: value
                        });
                      }}
                      label=""
                      suffix={product.type === "weight" ? "Gr." : "Un."}
                      helperText={{
                        text: "Stock: " + product.stock + (product.type === 'unit' ? ' un.' : ' gr.'),
                        type: product.stock < ((productQuantity && productQuantity.id === product.id && productQuantity.quantity) || quantity) ? "error" : "success",
                        show: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="paragraph-14" color={grayColor}>
                      {product.type === "weight" ? " Gr." : " Un."}
                    </Typography>
                  </Grid>
                </Grid>
              ),
              numericFormatter(product.type === "weight" ? product.amount * quantity / 1000 : product.amount * quantity),
              (
                <ButtonLevel
                  align="center"
                  title="X"
                  size="small"
                  onClick={() => handleRemoveProductFromCart(product.id)}
                  variant="text"
                  color="error"
                />
              )
            ]) || []
            }
            status={tableStatus}
            head="Carrito"
            search={{
              onChange: (value) => setSearch({
                ...search,
                value
              }),
              selectOption: (id: number) => {
                handleAddProductToCart(id, 1);
              },
              value: search.value,
              placeholder: "Buscar producto",
              options: search.options,
              loading: search.loading,
              noOptionText: "No se encontraron productos"
            }}
          />
        </Grid>
      </Grid>
      {cart && collectCartDrawer && (
        <CartPayDrawer
          cart={cart}
          setOpenModal={() => setCollectCartDrawer(null)}
          updatePage={handleGerActiveCart}
          setAlertStatus={(value: AlertType) => setAlertStatusCollect(value)}
        />
      )}
    </Grid>
  )
}
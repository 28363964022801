import { Drawer, Grid } from "@mui/material";
import "./drawer.scss";
import ButtonLevel from "../Buttons";
import Typography from "../Typography";
import icons from "../../../utils/icons";
import { primaryColor1, yellowColor3 } from "../../../utils/VARIABLES";

interface Props {
  title: string;
  disabled?: boolean;
  open: boolean;
  closeDrawer: () => void;
  children?: React.ReactElement;
  saveOnclick?: () => void;
  saveLabel?: string;
  drawerStatus?: DrawerStatus;
  onRetry?: () => void; // Use this to set the drawer status when click retry
  alert?: {
    title: string;
    description: string;
    icon: JSX.Element;
  }
}
export type DrawerStatus = "idle" | "loading" | "success" | "error";

export function SideDrawer(props: Props) {
  const { drawerStatus = "idle" } = props;
  return (
    <Grid className="side-drawer" container>
      <Drawer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.closeDrawer}
        closeAfterTransition
        PaperProps={{
          sx: {
            borderLeft: "3px",
            borderStyle: "solid",
            backgroundColor: "#ffff",
            color: "white",
            width: "32rem",
          },
        }}
        anchor="right"
      >
        <Grid className="side-drawer-container" container>
          <Grid className="side-drawer-header" container>
            <Grid className="body-title" item xs={10}>
              <Typography
                type="semibold"
                text={props.title}
                variant="paragraph-18"
              />
            </Grid>
            <Grid className="body-icon" item xs={2}>
              <ButtonLevel
                onClick={props.closeDrawer}
                icon={{ icon: icons.close({ color: primaryColor1 }) }}
              />
            </Grid>
          </Grid>
          <Grid container className="side-drawer-body">
            <>
              {(drawerStatus === "idle" || drawerStatus === "loading") && (
                <Grid container>
                  {props.alert && (
                    <Grid className="atention-box" container>
                      <Grid item xs={1} className="attention-box-icon" container>
                        {props.alert.icon}
                      </Grid>
                      <Grid item xs={11} className="attention-box-text" container>
                        <Typography
                          color={yellowColor3}
                          className="atention-box-title"
                          variant="paragraph-18"
                          type="bold"
                        >
                          {props.alert.title}
                        </Typography>
                        <Typography color={yellowColor3} variant="paragraph-14">
                          {props.alert.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {props.children}
                </Grid>
              )}
              {drawerStatus === "success" && successChildrenDrawer()}
              {drawerStatus === "error" && errorChildrenDrawer()}
            </>
          </Grid>
          {/* Footer */}
          {(drawerStatus === "idle" || drawerStatus === "loading") && (
            <Grid className="buttons-drawer" container>
              {(!props.saveLabel && (
                <Grid item xs className="button-drawer">
                  <ButtonLevel
                    align="center"
                    color="secondary"
                    disabled={props.disabled}
                    size="large"
                    onClick={props.closeDrawer}
                    title="Cerrar"
                  />
                </Grid>
              ))}
              {(props.saveLabel && (
                <>
                  <Grid item xs className="button-drawer">
                    <ButtonLevel
                      align="center"
                      color="primary"
                      size="large"
                      onClick={props.closeDrawer}
                      variant="outlined"
                      title="Cancelar"
                    />
                  </Grid>
                  <Grid item xs className="button-drawer">
                    <ButtonLevel
                      align="center"
                      color="primary"
                      disabled={props.disabled}
                      size="large"
                      onClick={props.saveOnclick}
                      title={props.saveLabel || "Guardar"}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          )}
          {drawerStatus === "success" && (
            <Grid className="buttons-drawer" container>
              <Grid item xs className="button-drawer">
                <ButtonLevel
                  align="center"
                  color="secondary"
                  size="large"
                  onClick={props.closeDrawer}
                  variant="contained"
                  title="Aceptar"
                />
              </Grid>
            </Grid>
          )}
          {drawerStatus === "error" && (
            <Grid className="buttons-drawer" container>
              <Grid item xs className="button-drawer">
                <ButtonLevel
                  align="center"
                  color="secondary"
                  size="large"
                  onClick={props.onRetry || props.closeDrawer}
                  variant="contained"
                  title="Reintentar"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Drawer>
    </Grid>
  );
}

const successChildrenDrawer = () => {
  return (
    <Grid className="drawer-exit" container>
      <Grid className="drawer-body-exit" container>
        <Grid className="drawer-exit-title" container>
          <Typography type="bold" variant="h2">
            Carga exitosa
          </Typography>
        </Grid>
        <Grid className="drawer-exit-img" container>
          <img src="https://cocheras-online-public.s3.amazonaws.com/drawer/drawerSuccess.png" width={200} alt="No results" />
        </Grid>
        <Grid className="drawer-exit-text" container>
          <Typography type="semibold" variant="paragraph-20">
            <>
              ¡Tus datos se crearon
              <br />y se guardaron correctamente!
            </>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const errorChildrenDrawer = () => {
  return (
    <Grid className="drawer-exit" container>
      <Grid className="drawer-body-exit" container>
        <Grid className="drawer-exit-title" container>
          <Typography type="bold" variant="h2">
            <>
              ¡Volvé a intentar <br /> en unos minutos!
            </>
          </Typography>
        </Grid>
        <Grid className="drawer-exit-img" container>
          <img src="https://cocheras-online-public.s3.amazonaws.com/drawer/drawerError.png" width={200} alt="No results" />
        </Grid>
        <Grid className="drawer-exit-text" container>
          <Typography type="semibold" variant="paragraph-20">
            <>
              ¡Lamentamos lo sucedido! <br /> Estamos trabajando para
              resolverlo, si el error persiste contacta con nuestro soporte.
            </>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { Grid } from "@mui/material";
import MenuTop from "../../components/MenuTop";
import { useContext, useEffect, useRef, useState } from "react";
import EmptyState from "../../components/kit/EmptyState/DesktopEmptyState";
import "./Movement.scss";
import {
  PaginatedResponse,
  defaultPaginateOptions,
  defaultPaginatedResponse,
} from "../../utils/generalServiceResponses";
import {
  Movement,
  deleteMovement,
  getAllMovements,
  movementPaymentMethoDTOEs,
} from "../../services/movements";
import BasicTable from "../../components/kit/BasicTable";
import ButtonLevel from "../../components/kit/Buttons";
import { dateFormatter, numericFormatter } from "../../utils/formatters";
import Alert, { AlertType } from "../../components/kit/Alert";
import CreateNewMovementDrawer from "../../components/Movement/CreateNewMovementDrawer";
import ConfirmationModal from "../../components/kit/Modal/ConfirmationModal";
import UpdateMovementDrawer from "../../components/Movement/UpdateMovementDrawer";
import Typography from "../../components/kit/Typography";
import { checkoutShiftToEs } from "../../services/checkout";
import { UserContext } from "../../services/user";

export default function MovementPage() {
  const { highestRole } = useContext(UserContext);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [movementToDeleteModal, setMovementToDeleteModal] = useState(0);
  const [movementToUpdate, setMovementToUpdate] = useState<Movement | null>(
    null
  );
  const [paginateOptions, setPaginateOptions] = useState(
    defaultPaginateOptions
  );
  const [movementPaginated, setMovementPaginated] = useState<
    PaginatedResponse<Movement>
  >(defaultPaginatedResponse);
  const [status, setStatus] = useState<"loading" | "success" | "error">(
    "loading"
  );
  const [deleteMovementStatus, setDeleteMovementStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [alertStatus, setAlertStatus] = useState<AlertType>("close");
  const [alertEdit, setAlertEdit] = useState<AlertType>("close");
  const [alertStatusConfirmation, setAlertStatusConfirmation] =
    useState<AlertType>("close");

  const handleMovementData = () => {
    setStatus("loading");
    (async () => {
      const data = await getAllMovements(paginateOptions);
      if (data !== undefined) {
        setMovementPaginated(data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    })();
  };

  const handleDeletedMovement = (id: number) => {
    setDeleteMovementStatus("loading");
    (async () => {
      const data = await deleteMovement(id);
      if (data !== undefined) {
        handleMovementData();
        setAlertStatusConfirmation("success");
        setDeleteMovementStatus("success");
      } else {
        setAlertStatusConfirmation("error");
        setDeleteMovementStatus("error");
      }

      setMovementToDeleteModal(0);
    })();
  };

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    // clear the timeout and prevent the callback from being called
    if (timeoutIdRef.current !== null) clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(handleMovementData, 300);
  }, [
    paginateOptions.page,
    paginateOptions.limit,
    paginateOptions.search,
    paginateOptions,
  ]);

  const amountTypeColumn = (movement: Movement) => {
    return (
      <Typography
        variant="table-content"
        className={`movement-type-color-${movement.amount > 0 ? "positive" : "negative"
          }`}
        text={numericFormatter(Math.abs(movement.amount).toString())}
      />
    );
  };

  const isEmptyState = () =>
    status === "success" &&
    movementPaginated.data.length === 0 &&
    paginateOptions.search === "";

  // Function to download movements as excel (.xslx)
  // const handleGetMovementBufferAndDownloadIt = () => {
  //   (async () => {
  //     const response = await getMovementsBuffer();

  //     if (response) {
  //       const uint8Array = new Uint8Array(response.data); // response.data is a Uint8Array or array of bytes

  //       const blob = new Blob([uint8Array]);
  //       const url = window.URL.createObjectURL(blob);

  //       const link = document.createElement('a');
  //       link.href = url;
  // link.setAttribute('download', `${dateFormatter(new Date().toDateString())}.xlsx`);

  //       document.body.appendChild(link);
  //       link.click();
  //     }

  //     return
  //   })();
  // };

  return (
    <Grid className="desktop-movement" container>
      {/* {isEmptyState() && (
        <Grid container className="movement-dashboard-emptyState">
          <EmptyState
            img="https://cocheras-online-public.s3.amazonaws.com/empty-states/movement-empty-state.png"
            text="Aún no hay movimientos"
            subtitle="El sistema no registra movimientos de cuenta."
            titleButton="Continuar"
            onClick={() => setOpenDrawer(true)}
            canCreate={highestRole !== "employee"}
          />
        </Grid>
      )} */}
      <Grid container className="movement-dashboard-content">
        <BasicTable
          rightElement={
            <ButtonLevel
              fillWidth={false}
              title="Agregá un movimiento"
              size="medium"
              onClick={() => setOpenDrawer(true)}
              variant="contained"
            />
          }
          status={status}
          head="Movimientos de cuenta"
          search={{
            value: paginateOptions.search,
            onChange: (value) => {
              if (value === "") setStatus("loading");
              setPaginateOptions({ ...paginateOptions, search: value });
            },
            placeholder: "Buscar",
          }}
          columns={[
            "Fecha y hora",
            "Monto",
            "Tipo de movimiento",
            "Caja",
            "Observación",
          ]}
          rows={movementPaginated.data.map((movement: Movement) => [
            dateFormatter(movement.date, true),
            amountTypeColumn(movement),
            movementPaymentMethoDTOEs(movement.paymentMethod),
            movement.checkout?.shift ? checkoutShiftToEs(movement.checkout.shift) : "Admin",
            movement.description,
          ])}
          options={[
            // {
            //   startIcon: icons.edit({ color: primaryColor1 }),
            //   label: "Editar",
            //   onClick: (rowIndex: number) => {
            //     const movement = movementPaginated.data[rowIndex];
            //     setMovementToUpdate(movement);
            //   },
            // },
            {
              label: "Eliminar",
              onClick: (rowIndex: number) => {
                const movement = movementPaginated.data[rowIndex];
                setMovementToDeleteModal(movement.id);
              },
            },
          ]}
          paginateOptions={{
            currentPage: paginateOptions.page,
            rowsPerPage: paginateOptions.limit,
            setCurrentPage: (page: number) => {
              setPaginateOptions({ ...paginateOptions, page });
            },
            totalItems: movementPaginated.totalItems,
          }}
        />
      </Grid>
      {alertStatus !== "close" && (
        <Alert
          setAlertStatus={setAlertStatus}
          severity={alertStatus}
          message={
            alertStatus === "success"
              ? "El movimiento se a creado con exíto"
              : "Ocurrío un error al crear el movimiento"
          }
        />
      )}
      {alertStatusConfirmation !== "close" && (
        <Alert
          setAlertStatus={setAlertStatusConfirmation}
          severity={alertStatusConfirmation}
          message={
            alertStatusConfirmation === "success"
              ? "El movimiento se a eliminado con exíto"
              : "Ocurrío un error al eliminar el movimiento"
          }
        />
      )}

      {alertEdit !== "close" && (
        <Alert
          setAlertStatus={setAlertEdit}
          severity={alertEdit}
          message={
            alertEdit === "success"
              ? "El movimiento se a editado con exíto"
              : "Ocurrío un error al editar el movimiento"
          }
        />
      )}
      {!!openDrawer && (
        <CreateNewMovementDrawer
          title="Crear nuevo Movimiento"
          setAlertStatus={setAlertStatus}
          updatePage={handleMovementData}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      )}
      {!!movementToDeleteModal && (
        <ConfirmationModal
          disabled={deleteMovementStatus === "loading"}
          confirmationOption={{
            onclick: () => handleDeletedMovement(movementToDeleteModal),
            title: "Eliminar",
            color: "error",
          }}
          title="Eliminar movimiento"
          description="Este movimiento se eliminara permanentemente.
          ¿Está seguro que desea continuar?"
          closeModal={() => setMovementToDeleteModal(0)}
          open={movementToDeleteModal ? true : false}
        />
      )}
      {!!movementToUpdate && (
        <UpdateMovementDrawer
          movement={movementToUpdate}
          setAlertStatus={setAlertEdit}
          updatePage={handleMovementData}
          openDrawer={!movementToUpdate ? false : true}
          setOpenDrawer={setMovementToUpdate}
        />
      )}
    </Grid>
  );
}
